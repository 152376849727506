import React from 'react';
import { FiX } from 'react-icons/fi';

export const CartService = ({line, staff, service, service_detail, date, currency, onRemove, deleteEnabled}) => {
  let service_duration = service_detail.service_business_detail_duration_a + service_detail.service_business_detail_duration_break + service_detail.service_business_detail_duration_b;
  return (
    <div>
      <div className="cart-item-container">
        <div className="service-info">
          <div className="cart-item-title">
            {`${service.service_name ? service.service_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null} (${service_detail.service_business_detail_name ? service_detail.service_business_detail_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null})`}
            <div className="service-description">
              {service_duration} mins{staff ? ' with ' + staff.firstname + ' ' + staff.lastname : null}
            </div>
            {/*<div className="service-description">
              {date.format('ddd Do MMM YYYY')}{line.booking_time ? ' at ' + line.booking_time : null}
            </div>*/}
          </div>
          <div className="cart-item-price">
            <span className="price">
              {service_detail.service_business_detail_poa === 1 ? 'POA' : currency + Number(service_detail.service_business_detail_price / 100).toFixed(2)}
            </span>
          </div>
          {deleteEnabled ?
            <FiX
              color={'#f44336'}
              onClick={(e) => onRemove(service_detail.service_business_detail_id)}
              style={{padding: '5px 0px 5px 5px', cursor: 'pointer'}}
            />
          : null}
        </div>
      </div>
      <div className="service-line"/>
    </div>
  );
}