import React, { Component } from 'react';
// Firebase
import "./utils/firebase";
import { getApp } from "firebase/app";
import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  getRedirectResult
} from 'firebase/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiMapPin } from 'react-icons/fi';
// Page components
import MaterialThemeProvider from './components/materialComponents/materialThemeProvider';
import WizardFormLandingPage from './WizardFormLandingPage';
import WizardFormLandingPage2 from './WizardFormLandingPage2';
import WizardFormLandingPage3 from './WizardFormLandingPage3';
import WizardFormLandingPage4 from './WizardFormLandingPage4';
import WizardFormLandingPage5 from './WizardFormLandingPage5';
import WizardFormLandingPage6 from './WizardFormLandingPage6';
import WizardFormLandingPageTerms from './WizardFormLandingPageTerms';

import BusinessLocationPage from './pages/BusinessLocationPage';

import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment-timezone';
import { Cart } from './components/Cart';
import { CartService } from './components/CartService';
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import remoteApi from './api/remoteApi';
import { FiChevronLeft } from 'react-icons/fi';
import { mediaUrl } from './utils/constants';
import queryString from 'query-string';

// Configure viewport height
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

let stripePromise;
if(process.env.NODE_ENV === "production") {
  stripePromise = loadStripe("pk_live_51GxzB6FQ8tU2VmRuSkgfhmWioyfOQwEYnCX2TBrbKNYlKGD9YZkufezTf9Zy5CCTmxsOz1HwagrrmGPcxvlgPYiY00odjejsAJ");
} else {
  stripePromise = loadStripe("pk_test_51GxzB6FQ8tU2VmRuTC3ufD9Vty8befyESFWsIsl9q5pyZEjl996fwL2bRx2wwLA1QCDfAHe5mo5dqNzidEkOGf9l00uBZXYCNp");
}

class WizardForm extends Component {
  constructor(props) {
    super(props);
    this.accountPage = this.accountPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onFocusChanged = this.onFocusChanged.bind(this);
    this.setUser = this.setUser.bind(this);
    this.selectAnotherService = this.selectAnotherService.bind(this);
    this.bookAgain = this.bookAgain.bind(this);
    this.setSignOut = this.setSignOut.bind(this);
    this.addService = this.addService.bind(this);
    this.removeService = this.removeService.bind(this);
    this.addTime = this.addTime.bind(this);
    this.state = {
      page: 0,
      showHideShopCart: "hidden",
      business_slug: '',
      booking_info:{partner_id: 0, staff: [], services: []},
      embedded: false,
      linked_service_id: null,
      business_id: null,
      business_name: null,
      business_img: null,
      business_logo: null,
      timezone_name: '',
      business_cancellation_period: null,
      business_cancellation_fee_enabled: null,
      business_cancellation_fee_percentage: null,
      business_no_show_fee_enabled: null,
      business_no_show_fee_percentage: null,
      business_currency: null,
      business_account_type: null,
      business_stripe_account: null,
      booking_terms_enabled: false,
      booking_terms: null,
      business_latitude: null,
      business_longitude: null,
      business_settings: {
        background: null,
        landing_header_background: null,
        landing_header_logo: null,
        landing_body_gradient_start: null,
        landing_body_gradient_end: null,
        landing_footer_background: null,
        landing_footer_title: null,
        landing_body_colour: null,
        service_background: null,
        service_header_background: null,
        service_header_title: null,
        service_category_background: null,
        service_category_title: null,
        service_item_title: null,
        service_item_description: null,
        service_tier_background: null,
        service_tier_title: null,
        service_tier_description: null,
        staff_background: null,
        staff_header_background: null,
        staff_header_title: null,
        staff_name_title: null,
        staff_position_title: null,
        times_background: null,
        times_header_background: null,
        times_header_title: null,
        times_time_title: null,
      },
      business_location_hours: null,
      
      booking_enabled: 0,
      open_days: null,
      date: null,
      time: null,
      time_added: null,
      focusedInput: false,
      firstDayOfWeek: 1,
      isOutsideRange: null,
      booking_range: 3,
      total: 0.00,
      deposit_total: 0,
      total_poa: false,
      booking_data: [],
      business_location_data: [],
      business_location_staff_map_data: [],
      business_location_id: null,
      service_category_data: [],
      service_data: [],
      service_detail_data: [],
      service_staff_map_data: [],
      service_location_map_data: [],
      staff_data: [],
      user: null,
      optionsModalVisible: false,
      socialRedirectLoading: false,
      socialRedirectError: false
    };
    // Redirect if there is no slug provided
    const parsedQueryString = queryString.parse(location.search);
    if(parsedQueryString?.business?.length > 0){
      const parsed = queryString.parse(location.search);
      this.state.business_slug = parsed.business;    
    } else {
      window.location = 'https://styler.digital';
      return;
    }
    // Detect if embedded
    if(parsedQueryString?.embedded?.length > 0){
      this.state.embedded = true;
    }
    // Detect service ID
    if(parsedQueryString?.service?.length > 0) {
      const parsedServiceId = parseInt(parsedQueryString.service);
      if(parsedServiceId) {
        this.state.linked_service_id = parsedServiceId;
        console.log(`Linked service is: ${this.state.linked_service_id}`);
      } else {
        console.error("Unable to parse linked service");
      }
    }
    // Firebase app
    this.firebaseApp = getApp();
  }

  async componentDidMount() {
    this.loadWidgetData();
  }

  async componentDidUpdate(_, prevState) {
    try {
      if(!prevState.business_id && this.state.business_id > 0) {
        await this.getBusinessSettings();
        await this.getBusinessHours();
        if(this.state.booking_enabled === 1) {
          // Load additional data
          await this.getBusinessLocations();
          await this.getBusinessServiceCategories();
          await this.getBusinessServices();
          await this.getBusinessStaff();
          // Detect if a social login redirect result is present
          this.detectSocialLogin();
          // Detect if a service has been linked to
          if(this.state.linked_service_id) {
            this.nextPage();
            return;
          }
          // Redirect to another starting page if required
          if(queryString.parse(location.search).account && queryString.parse(location.search).account.length > 0){
            this.setState({page: 7});
          }
        }
      }
    } catch(e) {
      console.log('Unable to load widget data');
    }
  }

  async loadWidgetData() {
    try {
      await this.getBusinessData();
    } catch(e) {
      console.log('Unable to load widget data');
    }
  }

  async getBusinessData() {
    try {
      let response = await remoteApi.getBusinessDetails(this.state.business_slug);
      let businessData = response.data.businessData;
      this.setState({
        business_img: businessData.business_img,
        business_id: businessData.id,
        business_name: businessData.business_name,
        booking_enabled: businessData.enable_booking,
        business_account_type: businessData.business_account_type_id,
        booking_range: businessData.advance_booking,
        business_cancellation_period: businessData.cancellation_period,
        timezone_name: businessData.timezone_name,
        business_cancellation_fee_enabled: businessData.cancellation_fee_enabled,
        business_cancellation_fee_percentage: businessData.cancellation_fee_percentage,
        business_no_show_fee_enabled: businessData.no_show_fee_enabled,
        business_no_show_fee_percentage: businessData.no_show_fee_percentage,
        business_currency: businessData.currency_symbol,
        business_logo: businessData.business_logo ? mediaUrl + businessData.business_logo : null,
        business_stripe_account: businessData.stripe_account,
        booking_terms_enabled: businessData.booking_terms_enabled,
        booking_terms: businessData.booking_terms,
        business_latitude: businessData.address_latitude,
        business_longitude: businessData.address_longitude,
        business_app_ios_url: businessData.app_business_apple_url,
        business_app_android_url: businessData.app_business_google_url,
        isOutsideRange: date => date.isBefore(moment().tz(businessData.timezone_name, false).startOf('day')) || date.isAfter(moment().tz(businessData.timezone_name, false).add(businessData.advance_booking,'month')),
        date: moment()
      });
      // Attempt to change document title
      try {
        document.title = `Booking | ${businessData.business_name}`;
      } catch(e) {
        console.log('Unable to update document title');
      }
      return businessData;
    } catch(e) {
      console.log('Unable to get business details');
      this.setState({ business_id: 0 });
      return null;
    }
  }

  async getBusinessLocations() {
    try {
      let response = await remoteApi.getBusinessLocations(this.state.business_id);
      let businessLocationData = response.data.businessLocationData;
      let businessLocationStaffMapData = response.data.businessLocationStaffMapData;
      this.setState({
        business_location_data: businessLocationData,
        business_location_staff_map_data: businessLocationStaffMapData
      });
    } catch(e) {
      console.log(e);
      console.log('Unable to get business location data');
    }
  }
  
  async getBusinessServiceCategories() {
    try {
      let response = await remoteApi.getBusinessServiceCategories(this.state.business_id);
      let serviceCategoryData = response.data.businessServiceCategoryData;
      this.setState({ service_category_data: serviceCategoryData });
    } catch(e) {
      console.log(e);
      console.log('Unable to get business service categories');
    }
  }

  async getBusinessServices() {
    try {
      let response = await remoteApi.getBusinessServices(this.state.business_id);
      let data = response.data;
      this.setState({
        service_data: data.serviceData,
        service_detail_data: data.serviceDetailData,
        service_staff_map_data: data.serviceStaffMapData,
        service_location_map_data: data.serviceBusinessLocationMapData
      });
    } catch(e) {
      console.log(e);
      console.log('Unable to get business services');
    }
  }

  async getBusinessStaff() {
    try {
      let response = await remoteApi.getBusinessStaff(this.state.business_id);
      let data = response.data;
      this.setState({
        staff_data: data.staffData
      });
    } catch(e) {
      console.log(e);
      console.log('Unable to get business staff');
    }
  }

  async getBusinessSettings() {
    try {
      let response = await remoteApi.getBusinessSettings(this.state.business_id);
      let data = response.data;
      this.setState({ business_settings: data.settingsData });
    } catch(e) {
      console.log('Unable to get business settings');
    }
  }

  async getBusinessHours() {
    try {
      let response = await remoteApi.getBusinessHours(this.state.business_id);
      let data = response.data;
      this.setState({ business_location_hours: data.businessHoursData });
    } catch(e) {
      console.log('Unable to get business location hours');
    }
  }

  async detectSocialLogin() {
    try {
      this.setState({ socialRedirectLoading: true });
      const auth = getAuth();
      const result = await getRedirectResult(auth);
      if(result) {
        let credential;
        switch(result.providerId) {
          case "google.com":
            credential = await GoogleAuthProvider.credentialFromResult(result);
            break;
          case "facebook.com":
            credential = await FacebookAuthProvider.credentialFromResult(result);
            break;
          default:
            return;
        }
        let tokenResponse = result._tokenResponse;
        await remoteApi.socialSignIn({
          idToken: tokenResponse.idToken,
          csrfToken: null,
          firstname: tokenResponse.firstName,
          lastname: tokenResponse.lastName,
          email: tokenResponse.email,
          business_id: this.state.business_id
        });
        // Restore stored state
        const pageNumber = localStorage.getItem('widget_page');
        if(pageNumber) {
          this.setState({ page: parseInt(pageNumber) });
          localStorage.removeItem('widget_page');
        }
        const bookingData = localStorage.getItem('widget_booking_data');
        if(bookingData) {
          this.setState({ booking_data: JSON.parse(bookingData) });
          localStorage.removeItem('widget_booking_data');
        }
        const time = localStorage.getItem('widget_time');
        if(time) {
          this.setState({ time });
          localStorage.removeItem('widget_time');
        }
        const date = localStorage.getItem('widget_date');
        if(date) {
          this.setState({ date: moment(date, 'YYYY-MM-DD') });
          localStorage.removeItem('widget_date');
        }
        this.setState({ socialRedirectLoading: false });
      } else {
        this.setState({ socialRedirectLoading: false });
      }
    } catch(e) {
      const pageNumber = localStorage.getItem('widget_page');
      if(pageNumber) {
        this.setState({ page: parseInt(pageNumber), socialRedirectLoading: false, socialRedirectError: true });
        localStorage.removeItem('widget_page');
      } else {
        this.setState({ socialRedirectLoading: false });
      }
      console.log('Unable to complete redirect authentication flow');
    }
  }

  nextPage() {
    // If no terms are required then skip this screen
    if(this.state.page === 0 && this.state.booking_terms_enabled !== 1) {
      this.setState({ page: 2 });
      return;
    }
    // If there is only one location, select and move on
    if(this.state.page === 1 && this.state.business_location_data.length === 1) {
      this.setState({ page: 3, business_location_id: this.state.business_location_data[0].business_location_id });
      return;
    }
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    if(this.state.page === 6){
      this.setState({ page: 4 });
      return;
    }
    if(this.state.page === 2 && this.state.booking_terms_enabled !== 1) {
      this.setState({ page: 0 });
      return;
    }
    this.setState({ page: this.state.page - 1 });
  }

  setUser(data) {
    this.setState({ user: data });
  }

  onFocusChanged(focusedInput) {
    this.setState({ focusedInput })
  }

  onDateChanged(date) {
    return new Promise((resolve) => {
      this.setState({date});
      resolve("Date changed");
    });
  }

  setBusinessLocation = (business_location_id) => {
    this.setState({ business_location_id });
    this.nextPage();
  }

  addService(service_business_detail_id, staff_id) {
    let booking_data = [...this.state.booking_data];
    let existing_booking_obj = booking_data.length > 0 && booking_data.find(x => x.service_business_detail_id === service_business_detail_id);
    if(existing_booking_obj) {
      return;
    }
    let booking_obj = {
      service_business_detail_id: service_business_detail_id,
      staff_id: staff_id,
      booking_time: null
    };
    booking_data.push(booking_obj);
    this.setState({ booking_data });
  }

  removeService (service_business_detail_id) {
    let booking_data = [...this.state.booking_data];
    if(booking_data && booking_data.length === 1 && this.state.page !== 3) {
      this.setState({ page: 3 });
    }
    let bookingServiceIndex = booking_data.findIndex(x => x.service_business_detail_id === service_business_detail_id);
    if(this.state.page === 6 && bookingServiceIndex !== booking_data.length - 1) {
      this.setState({ time: null, page: 4 });
    }
    booking_data = booking_data.filter(x => x.service_business_detail_id !== service_business_detail_id);
    this.setState({ booking_data });
  }
    
  addStaff = (id) => {
    let booking_data = this.state.booking_data;
    let book = booking_data[this.state.booking_data.length-1];
    if(book) book.staff_id = id
    this.setState({booking_data });
    this.selectAnotherService(0);

  }

  selectAnotherService = (ActionInt) => {
    if(ActionInt == 0){
      this.setState({ page: 2});
    } else if(ActionInt == 1) {
      this.toggleShopCart();
      this.setState({page: 5});
    }
  }

  async addTime(time) {
    try {
      this.setState({ time, time_added: moment() });
      this.nextPage();
      let input_data = {
        businessId: this.state.business_id,
        businessLocationId: this.state.business_location_id,
        bookingDate: moment(this.state.date).format('YYYY-MM-DD'),
        bookingTime: time,
        staffId: this.state.booking_data[0].staff_id
      };
      await remoteApi.reserveTime(input_data);
    } catch(e) {
      console.log('Unable to reserve booking_time');
    }
  }

  bookAgain = () => {
    this.setState({booking_data: [], time: null, date: moment(), page: 2});
  }

  setSignOut = () => {
    this.setState({user: null, page: 0});
  }

  toggleShopCart = () => {
    var css = (this.state.showHideShopCart === "hidden") ? "show" : "hidden";
    this.setState({"showHideShopCart":css});
  }
  
  accountPage() {
    this.setState({page: 7})
  }
  
  isActive(id) {
    if(this.booking_data && this.booking_data.length>0){
      return this.booking_data.services[0].id == id;
    } else {
      return false;
    }
  }

  renderRightPanel() {
    var backgroundStyle = {backgroundImage: `url(${mediaUrl + this.state.business_img})`};
    switch(this.state.page) {
      case 0: case 1: case 7: case 8: case 9:
        return null;
      default:
        let cart_total = 0;
        let cart_total_poa = false;
        let businessLocationObj = this.state.business_location_data.find(x => x.business_location_id === this.state.business_location_id);
        return (
          <div className={"right " + this.state.showHideShopCart} style={ backgroundStyle }>
            <div className="right-back-icon" onClick={() => this.toggleShopCart()}>
              <FiChevronLeft color={'#ffffff'} size={26}/>
            </div>
            <div className="cart-container">
              <div>
                <div className="cart-header">
                  <div className="cart-title">Your Booking</div>
                  <div className="cart-business">
                    <div>{this.state.business_name}</div>
                    {businessLocationObj ? <div style={{ fontSize: 13, marginTop: 3 }}><FiMapPin size={10} style={{ marginRight: 5 }}/>{businessLocationObj.business_location_name}</div> : null}
                  </div>
                </div>
                {this.state.booking_data.map((booking_service_obj, booking_service_index) => {
                    let service_detail_obj = this.state.service_detail_data.find(x => x.service_business_detail_id == booking_service_obj.service_business_detail_id);
                    let service_obj = this.state.service_data.find(x => x.service_id == service_detail_obj.service_business_id);
                    let staff_obj = this.state.staff_data.find(x => x.id === booking_service_obj.staff_id);
                    if(!service_detail_obj || !service_obj) {
                      return null;
                    }
                    if(service_detail_obj.service_business_detail_poa === 1) {
                      cart_total_poa = true;
                    } else {
                      cart_total += service_detail_obj.service_business_detail_price;
                    }
                    return (
                      <CartService
                        key={booking_service_obj.service_business_detail_id}
                        line={booking_service_obj}
                        service={service_obj}
                        service_detail={service_detail_obj}
                        staff={staff_obj}
                        date={this.state.date}
                        currency={this.state.business_currency}
                        onRemove={this.removeService}
                        deleteEnabled={true}
                      />
                    );
                  })  
                }
              </div>
              {Cart(this.state.booking_data.length, cart_total, cart_total_poa, this.state.business_currency)}
            </div>
          </div>
        );
    }
  }

  render() {
    const { page } = this.state;
    return (
      <MaterialThemeProvider businessSettings={this.state.business_settings}>
        <div className="page-back" style={{backgroundColor: this.state.business_settings?.background}}>
          {this.state.socialRedirectLoading ? (
            <div className="loading-container-spinner social-redirect-spinner-container">
              <CircularProgress size={45}/>
            </div>
          ) : null}
          <div className="cover-box">
            {page === 0 &&
              <WizardFormLandingPage
                nextPage={this.nextPage}
                accountPage={this.accountPage}
                {...this.state }
              />
            }
            {page === 1 &&
              <WizardFormLandingPageTerms
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.state }
              />
            }
            <div className="wrapper">
              {page === 2 &&
                <BusinessLocationPage
                  nextPage={this.nextPage}
                  previousPage={this.previousPage}
                  accountPage={this.accountPage}
                  onSubmit={this.setBusinessLocation}
                  {...this.state}
                />
              }
              {page === 3 &&
                <WizardFormLandingPage2
                  nextPage={this.nextPage}
                  previousPage={this.previousPage}
                  accountPage={this.accountPage}
                  removeService={this.removeService}
                  addService={this.addService}
                  isActive={this.isActive}
                  toggleShopCart={this.toggleShopCart}
                  {...this.state}
                />
              }
              {page === 4 &&
                <WizardFormLandingPage3
                  nextPage={this.nextPage}
                  accountPage={this.accountPage}
                  previousPage={this.previousPage}
                  dateChanged={this.onDateChanged}
                  focusChanged={this.onFocusChanged}
                  addTime={this.addTime}
                  {...this.state}
                />
              }
              {page === 5 &&
                <WizardFormLandingPage4
                  nextPage={this.nextPage}
                  accountPage={this.accountPage}
                  previousPage={this.previousPage}
                  setUser={this.setUser}
                  {...this.state}
                />
              }
              {page === 6 &&
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({elements, stripe}) => (
                      <WizardFormLandingPage5
                        nextPage={this.nextPage}
                        accountPage={this.accountPage}
                        previousPage={this.previousPage}
                        setUser={this.setUser}
                        bookAgain={this.bookAgain}
                        removeService={this.removeService}
                        elements={elements}
                        stripe={stripe}
                        {...this.state}
                      />
                    )}
                  </ElementsConsumer>
                </Elements>
              }
              {page === 7 &&
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({elements, stripe}) => (
                      <WizardFormLandingPage6
                        previousPage={this.previousPage}
                        accountPage={this.accountPage}
                        nextPage={this.nextPage}
                        setUser={this.setUser}
                        bookAgain={this.bookAgain}
                        setSignOut={this.setSignOut}
                        elements={elements}
                        stripe={stripe}
                        {...this.state }
                      />
                    )}
                  </ElementsConsumer>
                </Elements>
              }
              {this.renderRightPanel()}
            </div>
          </div>
        </div>
      </MaterialThemeProvider>
    );

  }
}


export default (WizardForm)
