import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { FiChevronRight, FiMapPin } from 'react-icons/fi';
import { getFormattedBusinessLocationAddress } from '../utils/formatting';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const BusinessLocationModal = props => {
    return (
        <div>
            <Dialog onClose={props.toggleVisible} open={props.visible} fullWidth maxWidth={'xs'} TransitionComponent={Transition}>
                <div className="staff-picker-header" style={{ paddingBottom: 2, fontSize: 18 }}>
                    Select Location
                </div>
                <div style={{ margin: 10 }}>
                    {props.visible && props.data && props.data.map((dataObj,) => {
                        return (
                            <div style={{ display: 'flex' }}>
                                <div className="payment-method-container" key={'businessLocation' + dataObj.business_location_id} onClick={() => props.handleLocationClick(dataObj)}>
                                    <div style={{display: 'flex', marginBottom: -7 }}>
                                        <div style={{ alignSelf: 'center' }}>
                                            <FiMapPin size={18}/>
                                        </div>
                                        <div style={{ marginLeft: 10 }}>
                                            <div>{dataObj.business_location_name}</div>
                                            <div style={{ color: '#aaaaaa', fontSize: 13 }}>{getFormattedBusinessLocationAddress(dataObj)}</div>
                                            {dataObj?.start_time && dataObj?.end_time ? (
                                                <div style={{ color: '#aaaaaa', fontSize: 13 }}>{dataObj.is_open ? `${dataObj.start_time.substring(0,5)} - ${dataObj.end_time.substring(0,5)}` : 'Closed'}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div style={{display: 'flex' }}>
                                        <FiChevronRight color={'#707070'} size={16}/>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};