import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiChevronRight } from 'react-icons/fi';

class Times extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if(this.props.loading) {
      return (
        <div className="no_times_available">
          <CircularProgress size={45}/>
        </div> 
      );
    } else {
      if(this.props.times.length > 0 && !(this.props.times.length == 1 && this.props.times[0].booking_time == '00:00')){
        return (
          <div className="times_container" style={{backgroundColor: this.props.business_settings?.times_background}}>
            {this.props.times.length > 0 && this.props.times.map(time => 
              <div key={'time' + time.booking_time}>
                <div className="service-line"/>
                <div
                  className="time-container"
                  style={{color: this.props.business_settings?.times_time_title}}
                  onClick={() => this.props.addTime(time.booking_time)}
                >
                  <div>
                    {time.booking_time}
                  </div>
                  <div>
                    <FiChevronRight
                      color={this.props.business_settings?.times_time_title}
                      size={22}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="service-line"/>
          </div>
        );
      } else {
        return (
          <div className="no_times_available">No Availability</div>
        );
      }
    }
  }
}

export default Times;