import React from 'react';

import moment from 'moment-timezone';

import { FiRepeat, FiMapPin, FiChevronLeft } from 'react-icons/fi';
import Button from "./materialComponents/CustomButtons/Button.js";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class Bookings extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            tabIndex: 0
        };
    }

    renderNoBookings(tabIndex) {
        let label,visible;
        switch(tabIndex) {
            case 0:
                label = "upcoming";
                visible = this.props.userUpcomingOrderData && this.props.userUpcomingOrderData.length === 0;
                break;
            case 1:
                label = "recurring";
                visible = this.props.userRecurringOrderData && this.props.userRecurringOrderData.length === 0;
                break;
            case 2:
                label = "previous";
                visible = this.props.userPreviousOrderData && this.props.userPreviousOrderData.length === 0;
                break;
            default:
                label = null;
                visible = false;
        }
        if (visible) {
            return (
                <div className="no-booking-results">
                    <div className="no-booking-results-container">
                        You don't have any {label} bookings.
                    </div>
                    <Button className="book-button" size="sm" disabled={this.props.bookingEnabled !== 1} onClick={() => { this.props.bookAgain() }}>
                        New booking
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div className="left">
                <div className="page-title" style={{color: this.props.businessSettings.times_header_title, backgroundColor: this.props.businessSettings.times_header_background}}>
                    <div style={{ display: 'flex' }}>
                        <div onClick={this.props.changeScreen} style={{ cursor: 'pointer', marginRight: 10 }}>
                            <FiChevronLeft size={20} style={{ marginBottom: -3 }} color={this.props.businessSettings.times_header_title}/>
                        </div>
                        <div>
                            Bookings
                        </div>
                    </div>
                </div>
                <div>
                    <Tabs
                        value={this.state.tabIndex}
                        onChange={(e, newValue) => this.setState({ tabIndex: newValue })}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        style={{ backgroundColor: this.props.businessSettings.service_category_background }}
                    >
                        <Tab label="Upcoming" style={{ color: this.props.businessSettings.service_category_title }}  />
                        <Tab label="Recurring" style={{ color: this.props.businessSettings.service_category_title }} />
                        <Tab label="Previous" style={{ color: this.props.businessSettings.service_category_title }} />
                    </Tabs>
                </div>
                <div style={{ maxHeight: 'calc(100% - 88px)', overflowY: 'auto', flex: 1 }}>
                    {this.state.tabIndex === 0 ?
                        <div>
                            {this.props.userUpcomingOrderData.map((userOrderObj, index) => {
                                let orderEvents = this.props.userOrderEventData.filter(x => x.user_order_id == userOrderObj.user_order_id);
                                let total = 0;
                                let total_poa = false;
                                orderEvents.forEach((orderEvent, index) => {
                                    if(orderEvent.service_business_detail_poa === 1){
                                    total_poa = true;
                                    } else {
                                    total = total + Number(orderEvent.service_business_detail_price);
                                    }
                                });
                                let cancelled = orderEvents[0].status == 'Cancelled';
                                return(
                                    <div className="booking-event-container" key={userOrderObj.user_order_id} style={cancelled ? {borderLeftColor: '#f44336'} : null}>
                                        {!cancelled ? <Button className="book-button" style={{ float: 'right', margin: 0 }} color="danger" size="sm" onClick={() => { this.props.cancelOrder(userOrderObj.user_order_id) }}>Cancel</Button> : null}
                                        <div className="booking-event-time">{moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}<div className="booking-event-date">, {moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY')}</div> {cancelled ? <div style={{display: 'inline-block', color: '#f44336'}}>CANCELLED</div> : null}</div>
                                        {userOrderObj.business_location_name ? <div style={{ fontSize: 13 }}><FiMapPin size={11} style={{ top: 2, marginRight: 5, position: 'relative' }}/>{userOrderObj.business_location_name}</div> : null}
                                        <div className="booking-event-date" style={{marginTop: 10, marginBottom: 10, fontWeight: '500'}}>Booking Ref: {userOrderObj.user_order_uid}</div>
                                        <div>
                                            {orderEvents.map((userOrderEventObj, index) => {
                                            var E_start = moment(userOrderEventObj.start, 'YYYY-MM-DD HH:mm:ss');
                                            var E_end = moment(userOrderEventObj.end, 'YYYY-MM-DD HH:mm:ss');
                                            return (
                                                <div key={userOrderEventObj.business_event_id}>
                                                <div className="booking-event-service-title">{userOrderEventObj.service_name ? userOrderEventObj.service_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null} ({userOrderEventObj.service_business_detail_name ? userOrderEventObj.service_business_detail_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null})<div style={{float: 'right'}}>{total_poa ? null : this.props.businessCurrency}{total_poa ? 'POA' : Number(userOrderEventObj.service_business_detail_price / 100).toFixed(2)}</div></div>
                                                <div className="booking-event-service-info">{E_end.diff(E_start, 'minutes')} mins with {userOrderEventObj.firstname + ' ' + userOrderEventObj.lastname}</div>
                                                </div>
                                            );
                                            })}
                                        </div>
                                        <div className="booking-service-separator"></div>
                                        <div className="booking-event-footer" style={{height: 39}}>
                                            <div className="booking-event-price">
                                                <div>Total</div>
                                                <div>{total_poa ? null : this.props.businessCurrency}{total_poa ? 'POA' : Number(total / 100).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {this.props.userUpcomingOrderData && this.props.userUpcomingOrderData.length === 0 ? this.renderNoBookings("upcoming") : null}
                        </div>
                    : null}
                    {this.state.tabIndex === 1 ?
                        <div>
                            {this.props.userRecurringOrderData.map((userRecurringOrderObj,) => {
                            let orderRecurringEvents = this.props.userRecurringEventData.filter(x => x.user_order_recurring_id == userRecurringOrderObj.user_order_recurring_id);
                            let total = 0;
                            let total_poa = false;
                            orderRecurringEvents.forEach((orderRecurringEvent, index) => {
                                if(orderRecurringEvent.service_business_detail_poa === 1){
                                total_poa = true;
                                } else {
                                total = total + Number(orderRecurringEvent.service_business_detail_price);
                                }
                            });
                            return(
                                <div className="booking-event-container" key={'r' + userRecurringOrderObj.user_order_recurring_id}>
                                <div className="booking-event-time">
                                    {moment(orderRecurringEvents[0].start_time, 'HH:mm:ss').format('HH:mm') + ' - ' + moment(orderRecurringEvents[0].end_time, 'HH:mm:ss').format('HH:mm')}
                                    <FiRepeat size={'16px'} color={'#000000'} style={{float: 'right'}} />
                                </div>
                                {userRecurringOrderObj.next_date ? <div className="booking-event-time">Next date: {userRecurringOrderObj.next_date.format('ddd Do MMM YYYY')}</div> : null}
                                <div className="booking-event-time">Start date: <div className="booking-event-date">{moment(orderRecurringEvents[0].start_date, 'YYYY-MM-DD').format('ddd Do MMM YYYY')}</div></div>
                                <div className="booking-event-time">End date: <div className="booking-event-date">{orderRecurringEvents[0].end_date ? moment(orderRecurringEvents[0].end_date, 'YYYY-MM-DD').format('ddd Do MMM YYYY') : '------'}</div></div>
                                <div className="booking-event-time">Occurs: <div className="booking-event-date">{orderRecurringEvents[0].business_events_recurring_frequency}</div></div>
                                {userRecurringOrderObj.business_location_name ? <div style={{ fontSize: 13 }}><FiMapPin size={11} style={{ top: 2, marginRight: 5, position: 'relative' }}/>{userRecurringOrderObj.business_location_name}</div> : null}
                                <div className="booking-event-date" style={{marginTop: 10, marginBottom: 10, fontWeight: '500'}}>Booking Ref: {userRecurringOrderObj.user_order_recurring_uid}</div>
                                <div>
                                    {orderRecurringEvents.map((userRecurringEventObj, index) => {
                                    var E_start = moment(userRecurringEventObj.start_time, 'HH:mm:ss');
                                    var E_end = moment(userRecurringEventObj.end_time, 'HH:mm:ss');
                                    return (
                                        <div key={userRecurringEventObj.business_events_recurring_id}>
                                        <div className="booking-event-service-title">{userRecurringEventObj.service_name ? userRecurringEventObj.service_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null} ({userRecurringEventObj.service_business_detail_name ? userRecurringEventObj.service_business_detail_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null})<div style={{float: 'right'}}>{total_poa ? null : this.props.businessCurrency}{total_poa ? 'POA' : Number(userRecurringEventObj.service_business_detail_price / 100).toFixed(2)}</div></div>
                                        <div className="booking-event-service-info">{E_end.diff(E_start, 'minutes')} mins with {userRecurringEventObj.firstname + ' ' + userRecurringEventObj.lastname}</div>
                                        </div>
                                    );
                                    })}
                                </div>
                                <div className="booking-service-separator"></div>
                                <div className="booking-event-footer" style={{height: 39}}>
                                    <div className="booking-event-price">
                                        <div>Total</div>
                                        <div>{total_poa ? null : this.props.businessCurrency}{total_poa ? 'POA' : Number(total / 100).toFixed(2)}</div>
                                    </div>
                                </div>
                                </div>
                            );
                            })}
                            {this.props.userRecurringOrderData && this.props.userRecurringOrderData.length === 0 ? this.renderNoBookings("recurring") : null}
                        </div>
                    : null}
                    {this.state.tabIndex === 2 ?
                        <div>
                            {this.props.userPreviousOrderData.map((userOrderObj, index) => {
                            let orderEvents = this.props.userOrderEventData.filter(x => x.user_order_id == userOrderObj.user_order_id);
                            let total = 0;
                            let total_poa = false;
                            orderEvents.forEach((orderEvent, index) => {
                                if(orderEvent.service_business_detail_poa === 1){
                                total_poa = true;
                                } else {
                                total = total + Number(orderEvent.service_business_detail_price);
                                }
                            });
                            let cancelled = orderEvents[0].status == 'Cancelled';
                            return(
                                <div className="booking-event-container" key={userOrderObj.user_order_id} style={cancelled ? {borderLeftColor: '#f44336'} : {borderLeftColor: '#8a8a8a'}}>
                                <div className="booking-event-time">{moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}<div className="booking-event-date">, {moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY')}</div> {cancelled ? <div style={{display: 'inline-block', color: '#f44336'}}>CANCELLED</div> : null}</div>
                                {userOrderObj.business_location_name ? <div style={{ fontSize: 13 }}><FiMapPin size={11} style={{ top: 2, marginRight: 5, position: 'relative' }}/>{userOrderObj.business_location_name}</div> : null}
                                <div className="booking-event-date" style={{marginTop: 10, marginBottom: 10, fontWeight: '500'}}>Booking Ref: {userOrderObj.user_order_uid}</div>
                                <div>
                                    {orderEvents.map((userOrderEventObj, index) => {
                                    var E_start = moment(userOrderEventObj.start, 'YYYY-MM-DD HH:mm:ss');
                                    var E_end = moment(userOrderEventObj.end, 'YYYY-MM-DD HH:mm:ss');
                                    return (
                                        <div key={userOrderEventObj.business_event_id}>
                                        <div className="booking-event-service-title">{userOrderEventObj.service_name ? userOrderEventObj.service_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null} ({userOrderEventObj.service_business_detail_name ? userOrderEventObj.service_business_detail_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null})<div style={{float: 'right'}}>{total_poa ? null : this.props.businessCurrency}{total_poa ? 'POA' : Number(userOrderEventObj.service_business_detail_price / 100).toFixed(2)}</div></div>
                                        <div className="booking-event-service-info">{E_end.diff(E_start, 'minutes')} mins with {userOrderEventObj.firstname + ' ' + userOrderEventObj.lastname}</div>
                                        </div>
                                    );
                                    })}
                                </div>
                                <div className="booking-service-separator"></div>
                                    <div className="booking-event-footer" style={{height: 39}}>
                                        <div className="booking-event-price">
                                            <div>Total</div>
                                            <div>{total_poa ? null : this.props.businessCurrency}{total_poa ? 'POA' : Number(total / 100).toFixed(2)}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                            })}
                        </div>
                    : null}
                    {this.renderNoBookings(this.state.tabIndex)}
                    </div>
            </div>
        );
    }
};

export default Bookings;