import React from "react";
import { createRoot } from "react-dom/client";
import WizardForm from "./WizardForm";
import * as Sentry from "@sentry/react";
import './App.css';

if(process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://3be8868d19b5477ab89ad159bfa75f23@o805225.ingest.sentry.io/6267111",
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0.2,
    });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<WizardForm/>);