import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiChevronLeft, FiEye, FiEyeOff } from 'react-icons/fi';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "./materialComponents/CustomInput/CustomInput.js";
import Button from "./materialComponents/CustomButtons/Button.js";
import {
  getAuth,
  setPersistence,
  createUserWithEmailAndPassword,
  inMemoryPersistence,
  signOut
} from 'firebase/auth';
import { getApp } from "firebase/app";
import remoteApi from '../api/remoteApi';

var firebaseApp = getApp();

class SignUpForm extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      passwordVisible: false,
      signupFirstName: '',
      signupFirstNameValid: true,
      signupLastName: '',
      signupLastNameValid: true,
      signupEmail: '',
      signupEmailValid: true,
      signupPhone: '',
      signupPhoneValid: true,
      signupPassword: '',
      signupPasswordValid: true,
      signupTermsChecked: false,
      signupTermsValid: true,
      signupError: null,
      signupLoading: false,
      signupRecaptcha: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePassword(password) {
    var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/;
    return re.test(String(password));
  }

  handleChange(event) {
    event.persist();
    let targetId = event.target.id;
    let targetValue = event.target.value;
    switch(targetId) {
      case 'firstname':
        this.setState((state) => state.signupFirstName = targetValue);
        break;
      case 'lastname':
        this.setState((state) => state.signupLastName = targetValue);
        break;
      case 'email':
        this.setState((state) => state.signupEmail = targetValue);
        break;
      case 'phone':
        this.setState((state) => state.signupPhone = targetValue);
        break;
      case 'password':
        this.setState((state) => state.signupPassword = targetValue);
        break;
    }
  }

  async submitCreateAccount() {
    let firstnameValid, lastnameValid, emailValid, phoneValid, passwordValid, termsValid;
    if(this.state.signupFirstName.length > 0){
      firstnameValid = true;
    } else {
      firstnameValid = false;
    }
    if(this.state.signupLastName.length > 0){
      lastnameValid = true;
    } else {
      lastnameValid = false;
    }
    if(this.validateEmail(this.state.signupEmail)){
      emailValid = true;
    } else {
      emailValid = false;
      this.setState({signupError: 'Invalid email address'});
    }
    if(isValidPhoneNumber(this.state.signupPhone)){
      phoneValid = true;
    } else {
      phoneValid = false;
      this.setState({signupError: 'Invalid mobile number'});
    }
    if(this.validatePassword(this.state.signupPassword)){
      passwordValid = true;
    } else {
      passwordValid = false;
      this.setState({signupError: 'Password must be a minimum of eight characters, at least one letter and one number.'});
    }
    termsValid = this.state.signupTermsChecked;
    this.setState({
      signupFirstNameValid: firstnameValid,
      signupLastNameValid: lastnameValid,
      signupEmailValid: emailValid,
      signupPhoneValid: phoneValid,
      signupPasswordValid: passwordValid,
      signupTermsValid: termsValid
    });
    if(firstnameValid && lastnameValid && emailValid && phoneValid && passwordValid && termsValid) {  
      try {
        this.setState({signupError: null, signupLoading: true});
        // Get parsed phone number
        let parsedPhoneNumber = parsePhoneNumber(this.state.signupPhone);
        let parsedPhoneNumberIso = parsedPhoneNumber.country;
        const auth = getAuth(firebaseApp);
        await setPersistence(auth, inMemoryPersistence);
        let authResponse = await createUserWithEmailAndPassword(auth, this.state.signupEmail, this.state.signupPassword);
        let idToken = await authResponse.user.getIdToken();
        // Sign up and create session cookie
        await remoteApi.signUp({
          idToken: idToken,
          csrfToken: null,
          firstname: this.state.signupFirstName.trim(),
          lastname: this.state.signupLastName.trim(),
          email: this.state.signupEmail,
          phone: parsedPhoneNumber.number,
          phone_iso: parsedPhoneNumberIso,
          business_id: this.props.business_id
        });
        await signOut(auth);
        this.props.nextPage();
      } catch(e) {
        // Handle Errors here.
        let errorCode = e.code;
        if(errorCode === 'auth/email-already-in-use'){
          this.setState({signupError: 'An account already exists with this email address'});
        } else if(errorCode === 'auth/invalid-email'){
          this.setState({signupError: 'Invalid email address'});
        } else if(errorCode === 'auth/operation-not-allowed'){
          this.setState({signupError: 'Operation denied'});
        } else if(errorCode === 'auth/weak-password'){
          this.setState({signupError: 'Password is too weak'});
        } else {
          this.setState({signupError: 'Sorry, an unexpected error has occured'});
        }
        this.setState({signupLoading: false});
      }
    } else {
      this.setState({signupLoading: false});
      return;
    }
  }

  render() {
    return (
      <div className="left">
        {this.state.signupLoading ?
          <div className="loading-container">
            <div className="loading-container-spinner">
              <CircularProgress size={45} color={'secondary'}/>
            </div>
          </div>
        : null}
        <div className="page-title">
          <div>
            <button className="back-button" onClick={() => this.props.changeScreen()}>
              <FiChevronLeft size={20} />
            </button>
            Create Account
          </div>
        </div>
        <div className="sign-in-container">
          <img className="ws-sign-in-logo" src='/logo/styler_logo_dark.svg' height="55px"/>
          <CustomInput
            labelText="First name"
            id="firstname"
            error={!this.state.signupFirstNameValid}
            formControlProps={{
              fullWidth: true
            }}
            className="sign-in-input"
            inputProps={{
              value: this.state.signupFirstName,
              onChange: this.handleChange
            }}
          />
          <CustomInput
            labelText="Last name"
            id="lastname"
            error={!this.state.signupLastNameValid}
            formControlProps={{
              fullWidth: true
            }}
            className="sign-in-input"
            inputProps={{
              value: this.state.signupLastName,
              onChange: this.handleChange
            }}
          />
          <CustomInput
            labelText="Email"
            id="email"
            error={!this.state.signupEmailValid}
            formControlProps={{
              fullWidth: true
            }}
            className="sign-in-input"
            inputProps={{
              value: this.state.signupEmail,
              onChange: this.handleChange
            }}
          />
          <div style={{paddingTop: 27, marginBottom: 17}}>
            <div style={Object.assign({fontSize: 12, fontFamily: 'Poppins'}, this.state.signupPhoneValid ? {color: '#AAAAAA'} : {color: '#ed5565'})}>
              Mobile Number
            </div>
            <PhoneInput
              id="phone"
              international
              defaultCountry="GB"
              placeholder="Mobile number"
              value={this.state.signupPhone}
              onChange={(value) => this.setState({signupPhone: value})}
            />
          </div>
          <CustomInput
            labelText="Password"
            id="password"
            error={!this.state.signupPasswordValid}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: this.state.passwordVisible ? "text" : "password",
              endAdornment: (
                <InputAdornment position="end">
                  {this.state.passwordVisible ? <FiEyeOff size={20} style={{cursor: 'pointer'}} color={'#000000'} onClick={() => { this.setState({passwordVisible: !this.state.passwordVisible}) }} /> : <FiEye size={20} style={{cursor: 'pointer'}} color={'#000000'} onClick={() => { this.setState({passwordVisible: !this.state.passwordVisible}) }} />}
                </InputAdornment>
              ),
              autoComplete: "off",
              value: this.state.signupPassword,
              onChange: this.handleChange
            }}
          />
          <FormControlLabel
            style={{marginTop: 8, marginBottom: 14}}
            control={
              <Checkbox
                checked={this.state.signupTermsChecked}
                onChange={() => this.setState({signupTermsChecked: !this.state.signupTermsChecked})}
                name="checkedB"
                color="default"
              />
            }
            label={
              <p style={{fontFamily: 'Poppins', fontWeight: '500', fontSize: 14}}>
                I agree to the Styler <a target="_blank" className="terms-link" href="https://help.styler.digital/legal/privacy_policy">Privacy Policy</a>, <a target="_blank" className="terms-link" href="https://help.styler.digital/legal/terms_of_use">Terms Of Use</a> and <a target="_blank" className="terms-link" href="https://help.styler.digital/legal/booking_terms">Booking Terms</a>.
              </p>}
          />
          <Button
            fullWidth
            className="sign-in-button"
            disabled={!this.state.signupTermsChecked}
            color="black"
            onClick={() => this.submitCreateAccount()}
            style={{ transition: '0.2s' }}
          >
            Create Account
          </Button>
          {this.state.signupError ?
            <div className="sign-in-error-container">
              <div className="sign-in-error-label">
                {this.state.signupError}
              </div>
            </div>
          : null}
        </div>
      </div>
    );
  }
};

export default SignUpForm;