import React, { useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiPlus, FiCreditCard, FiCheck, FiChevronDown, FiTrash2 } from 'react-icons/fi';
import { FaCcVisa, FaCcMastercard, FaCcAmex } from 'react-icons/fa';
import { CardElement } from "@stripe/react-stripe-js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from "./materialComponents/CustomButtons/Button.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const cardStyle = {
    style: {
        base: {
            color: "#000000",
            fontFamily: 'Poppins, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "14px",
            "::placeholder": {
            color: "#707070"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};

export const PaymentMethodsModal = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const addPaymentMethod = async () => {
        if(loading) {
            return;
        }
        setLoading(true);
        if(error) {
            setError(null);
        }
        const cardElement = props.elements.getElement(CardElement);
        let result = await props.stripe.createToken(cardElement);
        if (result.error) {
            setError(result.error.message);
            setLoading(false);
        } else {
            try {
                props.submitNewPaymentMethod(result.token.id);
                setLoading(false);
            } catch(err) {
                this.props.triggerNotification("An unexpected error occured whilst trying to add your payment method.", "danger", "bc", 4);
                setLoading(false);
            }
        }
    }
    return (
        <div>
            <Dialog onClose={props.toggleVisible} open={props.visible} fullWidth maxWidth={'xs'} TransitionComponent={Transition}>
                <div className="staff-picker-header" style={{ paddingBottom: 2, fontSize: 18 }}>
                    Select Payment Method
                </div>
                <div style={{ margin: 10 }}>
                    {props.data && props.data.map((dataObj, dataIndex) => {
                        let icon;
                        switch(dataObj.brand) {
                            case 'Visa':
                                icon = <FaCcVisa size={22} color={'#1A1F71'}/>;
                                break;
                            case 'MasterCard':
                                icon = <FaCcMastercard size={22} color={'#EB001B'}/>;
                                break;
                            case 'American Express':
                                icon = <FaCcAmex size={22} color={'#1D8ECE'}/>;
                                break;
                            default:
                                icon = <FiCreditCard size={22} color={'#000000'}/>;
                        }
                        return (
                            <div style={{ display: 'flex', marginBottom: 10 }}>
                                <div className="payment-method-container" key={'paymentMethod' + dataObj.id} onClick={() => props.submitDefaultPaymentMethod(dataObj)}>
                                    <div style={{display: 'flex', marginBottom: -7 }}>
                                        <div>
                                            {icon}
                                        </div>
                                        <div style={{ marginLeft: 10 }}>
                                            •••• {dataObj.last4}
                                        </div>
                                    </div>
                                    <div style={{display: 'flex' }}>
                                        {props.defaultPaymentMethod.id === dataObj.id ? (
                                            <FiCheck color={'#4caf50'} size={16}/>
                                        ) : (
                                            <FiPlus color={'#707070'} size={16}/>
                                        )}
                                    </div>
                                </div>
                                {props.allowDelete && props.allowDelete === true && props.defaultPaymentMethod.id !== dataObj.id ?
                                    <div
                                        className="payment-method-delete-container"
                                        onClick={() => props.submitDeletePaymentMethod(dataObj)}
                                    >
                                        <div className="payment-method-delete-icon">
                                            <FiTrash2 color={'#ffffff'} size={16} />
                                        </div>
                                    </div>
                                : null}
                            </div>
                        );
                    })}
                    <Accordion className="new-payment-method-accordion">
                        <AccordionSummary expandIcon={<FiChevronDown size={20}/>}>
                            <div style={{display: 'flex' }}>
                                <div>
                                    <FiPlus size={16} color={'#000000'} style={{ marginBottom: -2 }}/>
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    New Payment Method
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <CardElement id="card-element" options={cardStyle} />
                            </div>
                            <div>
                                <Button
                                    fullWidth
                                    disabled={loading}
                                    size="sm"
                                    onClick={addPaymentMethod}
                                    style={{ margin: '15px 0px 0px 0px' }}
                                >
                                    {loading ? <CircularProgress size={20} color={'secondary'}/> : 'Add Payment Method'}
                                </Button>
                            </div>
                            {error ?
                                <div className="sign-in-error-container">
                                    <div className="sign-in-error-label" style={{fontSize: 12}}>
                                        {error}
                                    </div>
                                </div>
                            : null}
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Dialog>
        </div>
    );
};