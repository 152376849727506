import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiChevronLeft } from 'react-icons/fi';
import { MdErrorOutline } from 'react-icons/md';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import CustomInput from "./materialComponents/CustomInput/CustomInput.js";
import Button from "./materialComponents/CustomButtons/Button.js";
import swal from 'sweetalert';
import remoteApi from '../api/remoteApi';

class AccountForm extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      passwordVisible: false,
      accountFirstName: '',
      accountFirstNameValid: true,
      accountLastName: '',
      accountLastNameValid: true,
      accountEmail: '',
      accountEmailValid: true,
      accountPhone: '',
      accountPhoneValid: true,
      accountError: null,
      accountLoading: false,
      accountRecaptcha: false
    };
    this.handleChange = this.handleChange.bind(this);

    this.state.accountFirstName = this.props.user.user_firstname;
    this.state.accountLastName = this.props.user.user_lastname;
    this.state.accountEmail = this.props.user.user_email;
    this.state.accountPhone = this.props.user.user_phone;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePassword(password) {
    var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/;
    return re.test(String(password));
  }

  handleChange(event) {
    event.persist();
    if(event.target.id == 'firstname'){    
      this.setState((state) => state.accountFirstName = event.target.value);
    } else if(event.target.id == 'lastname'){
      this.setState((state) => state.accountLastName = event.target.value);
    } else if(event.target.id == 'email'){
      this.setState((state) => state.accountEmail = event.target.value);
    } else if(event.target.id == 'phone'){
      this.setState((state) => state.accountPhone = event.target.value);
    }
  }

  async handleUpdateAccount() {
    if(this.state.accountEmail !== this.props.user.user_email) {
      let isConfirmed = await swal({
        title: "Are you sure?",
        text: "We have detected a change in your email address. If you continue you will be automatically signed out and you will need to verify your new email address before booking. Are you sure that you would like to change your email address?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: true,
            visible: true,
            className: "swal-custom-btn",
            closeModal: true,
          }, cancel: {
            text: "No",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          }
        },
        dangerMode: true,
      });
      if(!isConfirmed) {
        this.setState({accountLoading: false});
        return;
      }
      this.submitUpdateAccount();
    }
  }

  async submitUpdateAccount() {
    let firstnameValid, lastnameValid, emailValid, phoneValid;
    if(this.state.accountFirstName == this.props.user.user_firstname &&
      this.state.accountLastName == this.props.user.user_lastname &&
      this.state.accountEmail == this.props.user.user_email &&
      this.state.accountPhone == this.props.user.user_phone
    ){
      this.setState({accountError: 'No changes detected.'});
      return;
    }
    if(this.state.accountFirstName.length > 0){
      firstnameValid = true;
    } else {
      firstnameValid = false;
    }
    if(this.state.accountLastName.length > 0){
      lastnameValid = true;
    } else {
      lastnameValid = false;
    }
    if(this.state.accountEmail !== this.props.user.user_email) {
      emailValid = this.validateEmail(this.state.accountEmail);
    } else {
      emailValid = true;
    }
    if(isValidPhoneNumber(this.state.accountPhone)){
      phoneValid = true;
    } else {
      phoneValid = false;
      this.setState({accountError: 'Invalid mobile number.'});
    }
    this.setState({
      accountFirstNameValid: firstnameValid,
      accountLastNameValid: lastnameValid,
      accountEmailValid: emailValid,
      accountPhoneValid: phoneValid
    });
    if(!firstnameValid || !lastnameValid || !emailValid || !phoneValid){
      this.setState({accountLoading: false});
      return;
    }
    this.setState({accountError: null, accountLoading: true});
    // Parse phone number
    let parsedPhoneNumber = parsePhoneNumber(this.state.accountPhone);
    let parsedPhoneNumberIso = parsedPhoneNumber.country;
    // POST update
    try {
      await remoteApi.updateUser({
        firstname: this.state.accountFirstName.trim(),
        lastname: this.state.accountLastName.trim(),
        email: this.state.accountEmail,
        phone: parsedPhoneNumber.number,
        phone_iso: parsedPhoneNumberIso
      });
    } catch(error) {
      if(error.response && error.response.data && error.response.data.message) {
        this.setState({ accountLoading: false, accountError: error.response.data.message });
      } else {
        this.setState({ accountLoading: false, accountError: 'An unexpected error has occured.' });
      }
      return;
    }
    this.setState({accountLoading: false});
    swal({
      title: "Success",
      text: "Your account has been successfully updated.",
      icon: "success",
      buttons: false,
      timer: 2500,
      timerProgressBar: true
    });
    if(this.state.accountEmail !== this.props.user.user_email) {
      this.props.signOut();
    }
    // Need to update state with latest information
    let userData = this.props.user;
    userData.user_firstname = this.state.accountFirstName.trim();
    userData.user_lastname = this.state.accountLastName.trim();
    userData.user_phone = parsedPhoneNumber.number;
    userData.user_phone_iso = parsedPhoneNumberIso;
    this.props.setUser(userData);
    this.props.changeScreen();
  }

  getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }


  render() {
    return (
      <div className="left">
        {this.state.accountLoading ?
          <div className="loading-container">
            <div className="loading-container-spinner">
              <CircularProgress size={45} color={'secondary'}/>
            </div>
          </div>
        : null}
        <div className="page-title" style={{color: this.props.businessSettings.times_header_title, backgroundColor: this.props.businessSettings.times_header_background}}>
          <div style={{ display: 'flex' }}>
            <div onClick={this.props.changeScreen} style={{ cursor: 'pointer', marginRight: 10 }}>
              <FiChevronLeft size={20} style={{ marginBottom: -3 }} color={this.props.businessSettings.times_header_title}/>
            </div>
            <div>
              Account
            </div>
          </div>
        </div>
        <div className="sign-in-container">
          <CustomInput
            labelText="First name"
            id="firstname"
            error={!this.state.accountFirstNameValid}
            formControlProps={{
              fullWidth: true
            }}
            className="sign-in-input"
            inputProps={{
              value: this.state.accountFirstName,
              onChange: this.handleChange
            }}
          />
          <CustomInput
            labelText="Last name"
            id="lastname"
            error={!this.state.accountLastNameValid}
            formControlProps={{
              fullWidth: true
            }}
            className="sign-in-input"
            inputProps={{
              value: this.state.accountLastName,
              onChange: this.handleChange
            }}
          />
          <CustomInput
            labelText="Email"
            id="email"
            error={!this.state.accountEmailValid}
            formControlProps={{
              fullWidth: true
            }}
            className="sign-in-input"
            inputProps={{
              value: this.state.accountEmail,
              onChange: this.handleChange
            }}
          />
          <div style={{paddingTop: 10, marginBottom: 17}}>
            <div style={this.state.accountPhoneValid ? {color: '#AAAAAA', fontSize: 12, fontFamily: 'Poppins'} : {color: '#ed5565', fontSize: 12, fontFamily: 'Poppins'}}>Mobile Number</div>
            <PhoneInput
              id="phone"
              international
              defaultCountry="GB"
              placeholder="Mobile number"
              value={this.state.accountPhone}
              onChange={(value) => this.setState({accountPhone: value})}
            />
          </div>
          <Button fullWidth className="sign-in-button" color="black" onClick={() => this.handleUpdateAccount()}>Update</Button>
          {this.state.accountError ?
          <div className="sign-in-error-container">
            <div className="sign-in-error-label">
              <MdErrorOutline size={20} style={{marginRight: 5}}/> {this.state.accountError}
            </div>
          </div>
          : null}
        </div>
      </div>
    );
  }
};

export default AccountForm;