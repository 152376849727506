import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

export default function MaterialThemeProvider(props) {
    const { children, businessSettings } = props;
    const theme = createTheme({
        palette: {
          primary: {
            // Purple and green play nicely together.
            main: businessSettings && businessSettings.landing_header_background ? businessSettings.landing_header_background : '#192027',
          },
          secondary: {
            // This is green.A700 as hex.
            main: '#ffffff',
          },
        },
        typography: {
            fontFamily: [
              'Poppins',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
            ].join(','),
        },
    });
    return (
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    );
}