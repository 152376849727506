import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiChevronLeft  } from 'react-icons/fi';
import CustomInput from "./materialComponents/CustomInput/CustomInput.js";
import Button from "./materialComponents/CustomButtons/Button.js";
import remoteApi from '../api/remoteApi';

class ForgotPassword extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      forgotPasswordLoading: false,
      manualEmailValid: true,
      manualEmail: '',
      forgotPasswordError: null,
      sent: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async sendPasswordResetEmail() {
    if(!this.state.sent){
      if(!this.validateEmail(this.state.manualEmail)) {
        this.setState({forgotPasswordError: 'Invalid email address', forgotPasswordLoading: false});
        return;
      }
      try {
        let response = await remoteApi.resetPassword({ email: this.state.manualEmail });
        if(response.data.status && response.data.status === 'success') {
          // Email sent
          this.setState({ sent: true, forgotPasswordError: null });
        } else {
          this.setState({ forgotPasswordError: response.data.message });
        }
      } catch(e) {
        if(e.response && e.response.data && e.response.data.message) {
          this.setState({ forgotPasswordError: e.response.data.message });
        } else {
          this.setState({ forgotPasswordError: 'An unexpected error occured while trying to send you a reset password email. If they problem persists, please contact support@styler.digital.' });
        }
      }
    }
  }

  handleChange(event) {
    event.persist();           
    this.setState((state) => state.manualEmail = event.target.value);
  }

  render() {
    return (
      <div className="left">
        {this.state.forgotPasswordLoading ?
          <div className="loading-container">
            <div className="loading-container-spinner">
              <CircularProgress size={45} color={'secondary'}/>
            </div>
          </div>
        : null}
        <div className="page-title">
          <div>
            <button
              className="back-button"
              onClick={() => this.props.changeScreen()}
            >
              <FiChevronLeft size={20} />
            </button>
            Forgot Password
          </div>
        </div>
        <div className="sign-in-container">
          <p style={{ fontFamily: 'Poppins' }}>
            Enter your email address below and we'll get you back into your account in no time.
          </p>
          <CustomInput
            labelText="Email"
            id="manualEmail"
            error={!this.state.manualEmailValid}
            formControlProps={{
              fullWidth: true
            }}
            className="sign-in-input"
            inputProps={{
              value: this.state.manualEmail,
              onChange: this.handleChange
            }}
          />
          <Button
            fullWidth
            className="sign-in-button"
            color={this.state.sent ? "success" : "black"}
            onClick={() => this.sendPasswordResetEmail() }
          >
            Reset Password
            {this.state.sent ? ' Email Sent' : null}
          </Button>
          {this.state.forgotPasswordError ?
            <div className="sign-in-error-container">
              <div className="sign-in-error-label">
                {this.state.forgotPasswordError}
              </div>
            </div>
          : null}
        </div>
      </div>
    );
  }
};

export default ForgotPassword;