import React from 'react';
/* import ScrollMenu from 'react-horizontal-scrolling-menu';*/
/* REACT-DATES */
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import Button from "./components/materialComponents/CustomButtons/Button.js";
import 'react-dates/lib/css/_datepicker.css';
import Times from './components/times';
import { isMobile } from 'react-device-detect';
import moment from 'moment-timezone';
import { FiChevronLeft, FiCalendar } from 'react-icons/fi';
import remoteApi from './api/remoteApi';
import './Form.css';

class WizardFormLandingPage3 extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state= {
      closed_days: [],
      available_times: [],
      times_loading: true,
      busy_days: null,
      noslot_days: null,
      cal_disabled: true
    }
  }

  componentDidMount() {
    this.getBusinessClosedDates();
  }

  async getBusinessClosedDates() {
    try {
      let input_data = {
        businessId: this.props.business_id,
        businessLocationId: this.props.business_location_id,
        endDate: moment().tz(this.props.timezone_name).add(this.props.booking_range, 'month').format('YYYY-MM-DD')
      };
      let response = await remoteApi.getBusinessClosedDates(input_data);
      this.setState({ closed_days: response.data.data });
      this.getAvailableTimes();
    } catch(e) {
      this.setState({available_times: [], times_loading: false, cal_disabled: true});
    }
  }

  async getAvailableTimes() {
    try {
      this.setState({times_loading: true, cal_disabled: true});
      let input_data = {
        business_id: this.props.business_id,
        business_location_id: this.props.business_location_id,
        booking_date: moment(this.props.date).format('YYYY-MM-DD'),
        booking_data: this.props.booking_data
      };
      let response = await remoteApi.getAvailableTimes(input_data);
      this.setState({available_times: response.data, times_loading: false, cal_disabled: false});
    } catch(e) {
      console.error('Error loading times:', e);
      this.setState({available_times: [], times_loading: false, cal_disabled: false});
    }
  }

  render() {
    const CustomInfoPanel = () => (
      <div style={{ padding: '10px 21px', color: '#484848', fontSize: 14, fontWeight: '500' }}>
        <div className="info-panel-square-selected"/>Selected
        <div className="info-panel-square-busy"/>Busy
        <div className="info-panel-square-unavailable"/>No Availability
      </div>
    );
    return (
      <div className="left">
        <div
          className="page-title"
          style={{color: this.props.business_settings?.times_header_title, backgroundColor: this.props.business_settings?.times_header_background}}
        >
          <div style={{ display: 'flex' }}>
            <div onClick={this.props.previousPage} style={{ cursor: 'pointer', marginRight: 10 }}>
              <FiChevronLeft size={20} color={this.props.business_settings?.times_header_title} style={{ marginBottom: -3 }}/>
            </div>
            <div>
              Date & Time
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Button size="sm" onClick={() => this.props.accountPage()}>
              Account
            </Button>
          </div>        
        </div>
        <SingleDatePicker
          date={this.props.date} // momentPropTypes.momentObj or null
          onDateChange={(date) => { this.props.dateChanged(date).then(() => this.getAvailableTimes()); }} // PropTypes.func.isRequired
          focused={this.props.focusedInput} // PropTypes.bool
          onFocusChange={({ focused }) => this.props.focusChanged(focused)} // PropTypes.func.isRequired
          id="date-picker" // PropTypes.string.isRequired,
          numberOfMonths= {!isMobile ? 1 : 2} // Three if on an Iphone
          firstDayOfWeek={this.props.firstDayOfWeek}
          isOutsideRange={this.props.isOutsideRange}
          displayFormat="dddd Do MMM YYYY"
          isDayBlocked={date => this.state.closed_days.length > 0 ? this.state.closed_days.some(dateVal => dateVal === moment(date).format('YYYY-MM-DD')) : undefined}
          showDefaultInputIcon={true}
          daySize={45}
          readOnly={true}
          disabled={this.state.cal_disabled}
          withFullScreenPortal={isMobile ? true : false}
          orientation={isMobile ? "vertical" : "horizontal"}
          hideKeyboardShortcutsPanel={true}
          renderCalendarInfo={() => CustomInfoPanel()}
          customInputIcon={<FiCalendar size={20}/>}
        />
        <Times
          booking_data={this.props.booking_data}
          loading={this.state.times_loading} times={this.state.available_times}
          addTime={this.props.addTime}
          business_settings={this.props.business_settings}
        />
      </div>
    );
  };
}

export default (WizardFormLandingPage3);